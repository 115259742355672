import Layout from '../components/layout'
import Container from '../components/Container';
import Head from 'next/head'
import { 
  Center,
  Box,
  Heading
} from "@chakra-ui/react";

export default function Custom404() {
    return (
      <Layout>
            <Head>
        <title>404 - Not Found</title>
      </Head>
        <Container>
          <Center>
            <Box vh="100%" py="100px">
      <Heading>404 - Page Not Found</Heading>
      </Box>
    </Center>
    </Container>
    </Layout>
    )
  }